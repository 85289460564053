import React from "react";

const Footer= () => {
	return(
		<footer>
		<p>@2023~2024 By team_numo</p>
	</footer>
	);
};

export default Footer;
